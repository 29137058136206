.ag-theme-alpine-blue .ag-header-cell-text,
.ag-theme-alpine .ag-header-cell-text {
  justify-items: center;
  display: flex;
  align-items: center;
  text-align: center !important;
}

/* Custom background color and other styles */
.ag-theme-alpine-blue,
.ag-theme-alpine {
    --ag-header-background-color: #31adff;
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-height: 50px;
    --ag-font-size: 12px;
    --ag-font-family: monospace;
    --ag-header-column-separator-color: grey;
    --text-align: center;
}

.red-row {
    background-color: rgba(255, 239, 239, 0.9) !important;
    /* Set the background color for the 'red' rows */
  }
  
  .yellow-row {
    background-color: rgba(255, 255, 230, 0.9) !important;
    /* Set the background color for the 'yellow' rows */
  }
  
  .blue-row {
    background-color: rgba(238, 243, 255, 0.9) !important;
    /* Set the background color for the 'blue' rows */
  }
  
  .green-row {
    background-color: rgba(238, 255, 238, 0.9) !important;
    /* Set the background color for the 'blue' rows */
  }
  
  .cyt-row {
    background-color: rgba(253, 240, 217, 0.9) !important;
    /* Set the background color for the 'blue' rows */
  }
  
  .white-row {
    background-color: rgba(252, 252, 252, 0.9) !important;
    /* Set the background color for the 'blue' rows */
  }
  
  .all-row {
    background-color: rgba(199, 199, 199, 0.9) !important;
    /* Set the background color for the 'blue' rows */
  }

  .over {
    transform: scale(1.01);
    border: 1px dashed #eee;
  }

  .custom-tooltip {
    background-color: black !important;
    color: yellow !important;
    border: 1px solid yellow;
    padding: 5px;
    border-radius: 4px;
}

.custom-no-rows {
  font-size: 18px;
  color: #FF5733;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}
