@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

.rounded-full {
    border-radius: 9999px !important;
    margin-left: 29px !important;
}

.reg {
    text-decoration: none;
    border-bottom: 3px solid #31adff;

}

.cardimg {
    margin-left: -15%;
    padding-bottom: 5px !important;
    font-family: 'Open Sans', sans-serif !important;
    text-align: center;
    font-weight: 700;
}

.cardimg1 {
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.card-1 {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}

.b-sub {
    text-align: center;

}

.grid1 {
    background-color: #31adff;
}

.ag-theme-alpine-blue {
    --ag-header-height: 40px;
    --ag-header-background-color: #31adff !important;
    --ag-header-foreground-color: white;
    --ag-header-cell-hover-background-color: #0099ff;
    --ag-header-cell-moving-background-color: #0099ff;
}

.ag-theme-alpine-psh {
    --ag-header-height: 40px;
    --ag-header-background-color: #34c3ff !important;
    --ag-header-foreground-color: white;
    --ag-header-cell-hover-background-color: #0099ff;
    --ag-header-cell-moving-background-color: #0099ff;
}

.log-in {
    padding: 16px;
    text-align: center;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    border-radius: 0.5rem;
    margin: 8px 0px 0px;
    font-size: 1.5rem;
    line-height: 1.375;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.00735em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem;
}

.log-in1 {
    border-radius: 0.5rem;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 2, 0, 0.7) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.04) 0rem 0.125rem 0.25rem -0.0625rem;
}

.das {
    width: 75%;
    margin: auto;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 300;
}

.das3 {
    text-align: center;
    line-height: 1.25;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
    margin-top: 8px;
    width: 55%;
}

.das1 {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 133px;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0px groove rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
}

.das2 {
    display: flex;
    margin-left: 15px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -24px;
    opacity: 1;
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.das4 {
    display: flex;
    margin-left: 15px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -24px;
    opacity: 1;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.das5 {
    display: flex;
    margin-left: 15px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -24px;
    opacity: 1;
    background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.das6 {
    display: flex;
    margin-left: 15px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -24px;
    opacity: 1;
    background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.css-wlr1hm {
    user-select: none;
    width: 1em;
    height: 1em;
    /* overflow: hidden; */
    display: inline-block;
    text-align: center;
    flex-shrink: 0;
    font-size: 1.5rem;
}

.material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

.rd {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.625;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.0075em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 1;
    text-transform: capitalize;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
    font-weight: 700;
}

.red-box {
    color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 135px;
    overflow-wrap: break-word;
    background: rgb(230, 47, 45);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
}

.yellow-box {
    color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 135px;
    overflow-wrap: break-word;
    background: rgb(254, 255, 0);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
}

.blue-box {
    color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 135px;
    overflow-wrap: break-word;
    background: rgb(79, 154, 255);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
}

.white-box {
    color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 135px;
    overflow-wrap: break-word;
    background: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
}

.yellowc-box {
    color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 135px;
    overflow-wrap: break-word;
    background: #f59a24;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
}


* {
    font-family: 'Poppins', sans-serif;
}

body {
    background: #f0f9ff !important;
}

.myBox {
    background: linear-gradient(45deg, rgb(255, 240, 220), rgb(225, 255, 252));
    box-shadow: 0 0 20px rgba(235, 177, 177, 0.4);
}

.rs-picker-toggle {
    top: -2px !important
}

.fntRaleWay {
    font-family: 'Raleway', sans-serif;
}
.hide {
    display: none;
  }

/* Mobile devices (portrait and landscape) */
@media (max-width: 767px) {
    .log-in, .log-in1 {
        font-size: 1.2rem;
        padding: 12px;
    }
    
    .das, .das3 {
        width: 90%;
    }
    
    .cardimg {
        margin-left: 0;
        font-size: 16px;
    }

    .red-box, .yellow-box, .blue-box, .white-box, .yellowc-box {
        height: auto;
    }

    .rs-picker {
        font-size: 0.9rem;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .log-in, .log-in1 {
        font-size: 1.4rem;
        padding: 14px;
    }
    
    .das, .das3 {
        width: 80%;
    }

    .cardimg {
        margin-left: -10%;
        font-size: 18px;
    }

    .red-box, .yellow-box, .blue-box, .white-box, .yellowc-box {
        height: 150px;
    }

    .rs-picker {
        font-size: 1rem;
    }
}

/* Desktop */
@media (min-width: 1025px) {
    .log-in, .log-in1 {
        font-size: 1.5rem;
        padding: 16px;
    }
    
    .das, .das3 {
        width: 70%;
    }

    .cardimg {
        margin-left: -15%;
        font-size: 20px;
    }

    .red-box, .yellow-box, .blue-box, .white-box, .yellowc-box {
        height: 135px;
    }

    .rs-picker {
        font-size: 1.2rem;
    }
}
